import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Divider,
  Fab,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Badge,
  Icon,
  ButtonGroup,
  darken,
  Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FilterListIcon from "@material-ui/icons/FilterList";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import clsx from "clsx";

import { getCustomers } from "../../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../../actions/contactPersonActions";
import { getDivisionsByService } from "../../../../actions/divisionActions";
import { getServiceTypesByDivision } from "../../../../actions/serviceTypeActions";
import AttachmentRequirementModal from "../modals/AttachmentRequirementModal";
import AttachmentReasonRequirementModal from "../modals/AttachmentReasonModal";
import DocRequirementModal from "../modals/DocRequirementModal";
import {
  getCwsrById,
  updateCwsr,
  uploadDoc,
  submitCwsr,
} from "../../../../actions/cwsrActions";
import ReviewModal from "../modals/ReviewCwsrModal";

import {
  // diff,
  // addedDiff,
  // deletedDiff,
  updatedDiff,
  // detailedDiff,
} from "deep-object-diff";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    // marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontWeight: "bold",
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginTop: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  saveBtn2: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: 500,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#e68f0e",
  },
}));

const INIT_STATE = {
  employee: "",
  division: "",
  jobTitle: "",
  employeeId: "",
  customer: null,
  contactPerson: "",
  contactPersonEmail: "",
  contactPersonJob: "",
  contactPersonPhone: "",
  serviceDivision: "",
  serviceType: "",
  thirdParty: "",
  serviceRequirement: "",
  manpowerRequirement: [
    {
      qualification: "",
      quantity: 1,
      source: "",
    },
  ],
  equipmentRequirement: [
    {
      equipment: "",
      quantity: 1,
      specification: "",
    },
  ],
  consumableRequirement: [
    {
      consumable: "",
      quantity: 1,
      specification: "",
      requiredPPE: "",
    },
  ],

  serviceLocation: "",
  serviceDuration: 1,
  servicePeriod: "",
  serviceMethod: "",
  customerPrice: "",
  regulatoryRequirement: "",
  otherRequirement: "",

  modificationReason: "",
  modificationDoc: ""
};

const EditCWSR = ({ uploadDoc, updateCwsr, submitCwsr }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [dateReceived, setDateReceived] = useState(new Date());
  const [mobilizationDate, setMobilizationDate] = useState(new Date());
  const [document, setDocument] = useState([]);

  const [drawingSpecification, setDrawingSpecification] = useState([]);
  const [archive, setArchive] = useState({});
  const [isSaved, setIsSaved] = useState(false);

  const [state, setState] = useState(INIT_STATE);
  const [file, setFile] = useState(undefined);
  const [file2, setFile2] = useState(undefined);
  const [file3, setFile3] = useState([]);

  const [openDoc, setOpenDoc] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [openReasonAttachment, setOpenReasonAttachment] = useState(false);
  const [modifiedData, setModifiedData] = useState(null);

  const [openReview, setOpenReview] = useState(false);

  const { customers } = useSelector((state) => state.customer);
  const { contactPersons } = useSelector((state) => state.contactPerson);
  const { divisions } = useSelector((state) => state.division);
  const { services } = useSelector((state) => state.service);
  const { loading, cwsr, error } = useSelector((state) => state.cwsr);
  const { profile } = useSelector((state) => state.user);

  // console.log(moment(dateReceived).toDate());

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(getCwsrById(id));
  }, [dispatch, id]);

  useEffect(() => {
    let getData = cwsr && cwsr;

    if (cwsr && cwsr.status === "approved") {
      setArchive(() => ({
        ...getData,
      }));
    }
  }, [cwsr]);

  // console.log(archive);

  useEffect(() => {
    setModifiedData(
      JSON.parse(localStorage.getItem(`modifiedData-${cwsr && cwsr._id}`))
    );
  }, [cwsr]);

  useEffect(() => {
    const update = async () => {
      const data = {
        id,
        modifiedData: JSON.parse(
          localStorage.getItem(`modifiedData-${cwsr && cwsr._id}`)
        ),
      };

      if (isSaved) {
        const res = await new Promise((resolve, reject) => {
          resolve(updateCwsr(data));
        });
        return res;
      }
    };
    if (cwsr && cwsr.status === "approved") {
      update().then((doc) => {
        if (doc) {
          setIsSaved(false);
        }
      });
    }
  }, [id, isSaved, modifiedData, updateCwsr, cwsr]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDivisionsByService());
  }, [dispatch]);

  useEffect(() => {
    if (state.customer !== null) {
      dispatch(getContactPersonByCustomer(state.customer._id || ""));
    }
  }, [dispatch, state.customer]);

  useEffect(() => {
    if (state.serviceDivision !== "") {
      dispatch(getServiceTypesByDivision(state.serviceDivision));
    }
  }, [dispatch, state.serviceDivision]);

  useEffect(() => {
    if (state.contactPerson) {
      contactPersons &&
        contactPersons.map(
          (item) =>
            item.name === state.contactPerson &&
            setState((prev) => ({
              ...prev,
              contactPersonEmail: item && item.email,
              contactPersonJob: item && item.designation,
              contactPersonPhone: item && item.phone,
            }))
        );
    }
  }, [state.contactPerson, contactPersons]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employee: (cwsr && cwsr.employee) || "",
      division: (cwsr && cwsr.division && cwsr.division.name) || "",
      jobTitle: (cwsr && cwsr.jobTitle) || "",
      employeeId: (cwsr && cwsr.employeeId) || "",
      customer: (cwsr && cwsr.customer) || null,
      contactPerson: (cwsr && cwsr.contactPerson) || "",
      serviceDivision: (cwsr && cwsr.serviceDivision) || "",
      serviceType: (cwsr && cwsr.serviceType) || "",
      serviceRequirement: (cwsr && cwsr.serviceRequirement) || "",
      thirdParty: cwsr && (cwsr.thirdParty === true ? "Yes" : "No"),
      manpowerRequirement: cwsr && cwsr.manpowerRequirement,
      equipmentRequirement: cwsr && cwsr.equipmentRequirement,
      consumableRequirement: cwsr && cwsr.consumableRequirement,
      serviceLocation: (cwsr && cwsr.serviceLocation) || "",
      serviceDuration: (cwsr && cwsr.serviceDuration) || "",
      servicePeriod: (cwsr && cwsr.servicePeriod) || "",
      serviceMethod: (cwsr && cwsr.serviceMethod) || "",
      customerPrice: (cwsr && cwsr.customerPrice) || "",
      regulatoryRequirement: (cwsr && cwsr.regulatoryRequirement) || "",
      otherRequirement: (cwsr && cwsr.otherRequirement) || "",
      modificationReason: (cwsr && cwsr.modificationReason) || "",
      modificationDoc: (cwsr && cwsr.modificationDoc) || "",

      acceptedBy: (cwsr && cwsr.acceptedBy) || "",
      acceptedAt: (cwsr && cwsr.acceptedAt) || "",
      accepterComment: (cwsr && cwsr.accepterComment) || "",

      approvedBy: (cwsr && cwsr.approvedBy && cwsr.approvedBy.userName) || "",
      approvedAt: (cwsr && cwsr.approvedAt) || "",
      approverComment: (cwsr && cwsr.approverComment) || "",

      declinedBy: (cwsr && cwsr.declinedBy && cwsr.declinedBy.userName) || "",
      declinedAt: (cwsr && cwsr.declinedAt) || "",
      declineComment: (cwsr && cwsr.declineComment) || "",

      rejectedBy: (cwsr && cwsr.rejectedBy && cwsr.rejectedBy.userName) || "",
      rejectedAt: (cwsr && cwsr.rejectedAt) || "",
      rejectComment: (cwsr && cwsr.rejectComment) || "",

      reviewedBy: (cwsr && cwsr.reviewedBy && cwsr.reviewedBy.userName) || "",
      reviewedAt: (cwsr && cwsr.reviewedAt) || "",
      modifiedBy: (cwsr && cwsr.modifiedBy && cwsr.modifiedBy.userName) || "",
      modifiedAt: (cwsr && cwsr.modifiedAt) || "",
      reviewerComment: (cwsr && cwsr.reviewerComment) || "",
      submittedBy:
        (cwsr && cwsr.submittedBy && cwsr.submittedBy.userName) || "",
      submittedAt: (cwsr && cwsr.submittedAt) || "",
    }));

    setDateReceived(cwsr && cwsr.dateReceived);
    setMobilizationDate(cwsr && cwsr.mobilizationDate);
    setDrawingSpecification(cwsr && cwsr.drawingSpecification);
    setDocument(cwsr && cwsr.document);
  }, [cwsr]);

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });

  const handleUploadDrawing = (e) => {
    setFile(e.target.files);
  };

  const handleUploadDocument = (e) => {
    setFile2(e.target.files);
  };

  const handleUploadReasonDocument = (e) => {
    setFile3(e.target.files);
  };

  useEffect(() => {
    const upload = async () => {
      if (file) {
        for (let i = 0; i < file.length; i++) {
          const formData = new FormData();

          formData.append("doc", file[i]);

          let doc = await new Promise((resolve, reject) => {
            resolve(uploadDoc(formData));
          });
          if (doc) {
            setDrawingSpecification((prev) => prev.concat(doc));
          }
        }
      }
    };
    upload();
  }, [file, uploadDoc]);

  useEffect(() => {
    const upload = async () => {
      if (file2) {
        for (let i = 0; i < file2.length; i++) {
          const formData = new FormData();

          formData.append("doc", file2[i]);
          let doc = await new Promise((resolve, reject) => {
            resolve(uploadDoc(formData));
          });
          if (doc) {
            setDocument((prev) => prev.concat(doc));
          }
        }
      }
    };
    upload();
  }, [file2, uploadDoc]);

  useEffect(() => {
    const upload = async () => {
      if (file3) {
        for (let i = 0; i < file3.length; i++) {
          const formData = new FormData();

          formData.append("doc", file3[i]);

          let doc = await new Promise((resolve, reject) => {
            resolve(uploadDoc(formData));
          });
          if (doc) {
            setState((prev) => ({
              ...prev,
              modificationDoc: doc,
            }));
          }
        }
      }
    };
    upload();
  }, [file3, uploadDoc]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeDate = (date) => {
    setDateReceived(date);
  };

  const handleChangeMobilizationDate = (date) => {
    setMobilizationDate(date);
  };

  const handleChangeManpower = (i) => (e) => {
    e.persist();
    const newManpower = state.manpowerRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      manpowerRequirement: newManpower,
    }));
  };

  const handleChangeEquipment = (i) => (e) => {
    e.persist();
    const newEquipment = state.equipmentRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      equipmentRequirement: newEquipment,
    }));
  };

  const handleChangeConsumable = (i) => (e) => {
    e.persist();

    const newConsumable = state.consumableRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      consumableRequirement: newConsumable,
    }));

  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isBD = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => ((grp.name === "user" || grp.name === "manager") && grp.module === "sales")
    )
    : false;

  // const isServices = profile
  //   ? profile &&
  //   profile.groups &&
  //   profile.groups.some(
  //     (grp) => grp.name === "head" && grp.module === "sales"
  //   )
  //   : false;

  const isServices = profile
    ? profile &&
    profile.division &&
    (profile?.division?.code === "AMD" || profile?.division?.code === "AID" || profile?.division?.code === "DSD" || 
    profile?.division?.code === "OPD" || profile?.division?.code === "SAD" || profile?.division?.code === "SSD" || 
    profile?.division?.code === "SST")
    : false;

  const getUpdatedInfo = async () => {
    const stateData = {
      ...state,
      // dateReceived: moment(dateReceived).toDate(),
      // mobilizationDate: moment(mobilizationDate).toDate(),
      document,
      drawingSpecification,
      thirdParty: undefined,
      division: undefined,
    };
    let val;
    if (Object.keys(stateData).length > 0) {
      val = updatedDiff(archive, stateData);
    }

    let modifiedArray =
      JSON.parse(localStorage.getItem(`modifiedData-${cwsr && cwsr._id}`)) ||
      [];
    modifiedArray.push(val);
    localStorage.setItem(
      `modifiedData-${cwsr && cwsr._id}`,
      JSON.stringify(modifiedArray)
    );

    return val;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await getUpdatedInfo();

    let data = {
      ...state,
      thirdParty: state.thirdParty === "Yes" ? true : false,
      document,
      drawingSpecification,
      division: cwsr && cwsr.division,
      dateReceived: moment(dateReceived).toDate(),
      mobilizationDate: moment(mobilizationDate).toDate(),
      customer: state.customer && state.customer._id,
      id,
      // modifiedData,
    };

    setIsSaved(true);

    const res = await new Promise((resolve, reject) => {
      resolve(updateCwsr(data));
    });

    if (res) {
      dispatch(getCwsrById(id));
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "CWSR Updated Successfully.",
        success: true,
      }));
    }
  };

  const handleSubmitHandler = async () => {
    const data = {
      path: "sales/cwsr/view",
      id,
    };

    const res = await new Promise((resolve, reject) => {
      resolve(submitCwsr(data));
    });

    if (res) {
      dispatch(getCwsrById(id));
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "CWSR Submitted Successfully.",
        success: true,
      }));
      setTimeout(() => {
        history.push("/sales/cwsr");
      }, 3500);
    }
  };

  const handleSubmitReasonHandler = async () => {
    console.log(state)
    await getUpdatedInfo();
    let data = {
      ...state,
      thirdParty: state.thirdParty === "Yes" ? true : false,
      document,
      drawingSpecification,
      division: cwsr && cwsr.division,
      dateReceived: moment(dateReceived).toDate(),
      mobilizationDate: moment(mobilizationDate).toDate(),
      customer: state.customer && state.customer._id,
      id,
    };
    // console.log(data)
    const res = await new Promise((resolve, reject) => {
      resolve(updateCwsr(data));
    });

    if (res) {
      const data = {
        path: "sales/cwsr",
        id,
      };

      const res2 = await new Promise((resolve, reject) => {
        resolve(submitCwsr(data));
      });

      if (res2) {
        dispatch(getCwsrById(id));
        setFeed((prev) => ({
          loading: false,
          open: !prev.open,
          message: "CWSR Update Request Sent Successfully.",
          success: true,
        }));
        setTimeout(() => {
          history.push("/sales/cwsr");
        }, 3500);
      }
    }
  };

  const handleAddManpower = () => {
    const data = {
      qualification: "",
      quantity: 1,
      source: "",
    };
    setState((prev) => ({
      ...prev,
      manpowerRequirement: prev.manpowerRequirement.concat([data]),
    }));
  };

  const handleRemoveManpower = (i) => {
    setState((prev) => ({
      ...prev,
      manpowerRequirement: prev.manpowerRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleAddEquipment = () => {
    const data = {
      equipment: "",
      quantity: 1,
      specification: "",
    };
    setState((prev) => ({
      ...prev,
      equipmentRequirement: prev.equipmentRequirement.concat([data]),
    }));
  };

  const handleRemoveEquipment = (i) => {
    setState((prev) => ({
      ...prev,
      equipmentRequirement: prev.equipmentRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleAddConsumables = () => {
    const data = {
      consumable: "",
      requiredPPE: "",
      quantity: 1,
      specification: "",
    };
    setState((prev) => ({
      ...prev,
      consumableRequirement: prev.consumableRequirement.concat([data]),
    }));
  };

  const handleRemoveConsumable = (i) => {
    setState((prev) => ({
      ...prev,
      consumableRequirement: prev.consumableRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleOpenDoc = () => {
    setOpenDoc(true);
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
  };

  const handleOpenAttachment = () => {
    setOpenAttachment(true);
  };

  const handleCloseAttachment = () => {
    setOpenAttachment(false);
  };

  const handleOpenReasonAttachment = () => {
    setOpenReasonAttachment(true);
  };

  const handleCloseReasonAttachment = () => {
    setOpenReasonAttachment(false);
  };

  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };

  const isCommercials = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "reviewer" && grp.module === "sales"
    )
    : false;

  return (
    <div>
      <DocRequirementModal
        {...{ openDoc, handleCloseDoc, docs: drawingSpecification }}
      />
      <AttachmentRequirementModal
        {...{ openAttachment, handleCloseAttachment, docs: document }}
      />
      <AttachmentReasonRequirementModal
        {...{ openReasonAttachment, handleCloseReasonAttachment, docs: state.modificationDoc }}
      />
      <ReviewModal {...{ openReview, handleCloseReview, id }} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {feed.success ? (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="success"
            message={feed.message}
          />
        ) : (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="error"
            message={feed.message}
          />
        )}
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              className={classes.backBtn}
              onClick={() => history.push("/sales/cwsr")}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Paper variant="outlined" className={classes.status}>
              <Typography variant="overline" className={classes.statusTitle}>
                Document No.
              </Typography>
              <Divider className={classes.divider2} orientation="vertical" />
              {loading ? "Loading..." : cwsr && cwsr.documentNo}
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <Typography variant="overline" className={classes.title}>
              CWSR Form <Icon className="far fa-file-alt" />
            </Typography>
          </Grid>

          <Grid item>
            <Paper
              variant="outlined"
              className={clsx(
                cwsr && cwsr.status === "draft"
                  ? classes.statusYellow
                  : cwsr && cwsr.status === "submitted"
                    ? classes.statusOrange
                    : cwsr && cwsr.status === "approved"
                      ? classes.statusGreen
                      : cwsr && cwsr.status === "accepted"
                        ? classes.statusDarkGreen
                        : classes.statusRed,
                classes.status
              )}
            >
              <Typography variant="overline" className={classes.statusTitle}>
                Status
              </Typography>
              <Divider className={classes.divider2} orientation="vertical" />
              {loading
                ? "Loading..."
                : cwsr && cwsr.status.toUpperCase()}
            </Paper>
          </Grid>
        </Grid>

        <form onSubmit={handleSave}>
          <Typography variant="overline" className={classes.title2}>
            A. Basic Information
          </Typography>

          <Paper variant="outlined" square className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Prepared By"
                  name="employee"
                  value={state.employee || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Division/Subdivision"
                  name="division"
                  value={state.division || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  className={classes.marginBottom}
                  autoOk
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Date Received"
                  fullWidth
                  inputVariant="outlined"
                  value={dateReceived}
                  // disabled={cwsr.status === "submitted"}
                  onChange={handleChangeDate}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                        <InputAdornment position="end">
                          <IconButton
                            disabled={dateReceived === null}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDateReceived(null);
                            }}
                          >
                            {dateReceived ? <CloseIcon /> : <FilterListIcon />}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Typography variant="overline" className={classes.title2}>
            B. Customer Information
          </Typography>

          <Paper variant="outlined" square className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country-code"
                  options={customers}
                  value={state.customer}
                  onChange={(event, newValue) => {
                    setState((prev) => ({
                      ...prev,
                      customer: newValue,
                    }));
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option) => option.name}
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  style={{ marginTop: -10 }}
                  autoComplete
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer Name"
                      margin="normal"
                      fullWidth
                      required
                      variant="outlined"
                      // error={!code ? true : false}
                      value={state.customer}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled",
                        name: "customer",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="contactPerson">Contact Person</InputLabel>
                  <Select
                    labelId="contactPerson"
                    name="contactPerson"
                    value={state.contactPerson}
                    onChange={handleChange}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {contactPersons &&
                      contactPersons.map((item) => (
                        <MenuItem key={item._id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  label="Contact Person Email"
                  name="contactPersonEmail"
                  value={state.contactPersonEmail || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Job Title"
                  name="contactPersonJob"
                  value={state.contactPersonJob || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  type="tel"
                  name="contactPersonPhone"
                  value={state.contactPersonPhone || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
          <Typography variant="overline" className={classes.title2}>
            C. Service Information
          </Typography>

          <Paper variant="outlined" square className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="serviceDivision">Service Division</InputLabel>
                  <Select
                    labelId="serviceDivision"
                    name="serviceDivision"
                    value={state.serviceDivision}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {divisions &&
                      divisions.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="serviceType">Service Type</InputLabel>
                  <Select
                    labelId="serviceType"
                    name="serviceType"
                    value={"" || state.serviceType}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {services &&
                      services.map((item) => (
                        <MenuItem key={item._id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" component="fieldset">
                  <FormLabel>Is this a third party service?</FormLabel>
                  <RadioGroup
                    row
                    onChange={handleChange}
                    value={state.thirdParty}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      name="thirdParty"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      name="thirdParty"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
          </Paper>
          <Typography variant="overline" className={classes.title2}>
            D. Work Scope Requirements
          </Typography>

          <Paper variant="outlined" square className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Service Requirements"
                  multiline
                  rows={4}
                  name="serviceRequirement"
                  value={state.serviceRequirement || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <Typography variant="overline" className={classes.title2}>
                E. Other Requirements
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={drawingSpecification && drawingSpecification.length === 0}
                onClick={handleOpenDoc}
              >
                <Badge
                  color="secondary"
                  badgeContent={drawingSpecification && drawingSpecification.length}
                >
                  <AttachFileIcon />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>

          <Paper variant="outlined" square className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              spacing={2}
              className={classes.grid}
            >
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <label htmlFor="file">Drawing Specification </label>
                  <input
                    type="file"
                    variant="outlined"
                    accept="image/*,.pdf"
                    multiple
                    id="file"
                    className={classes.input}
                    onChange={handleUploadDrawing}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label>Service Location(s) offshore/onshore *</label>
                <TextField
                  label="Offshore/onshore"
                  variant="outlined"
                  fullWidth
                  required
                  value={state.serviceLocation || ""}
                  name="serviceLocation"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label htmlFor="customerPrice">
                  Customer Price & Cost Target *
                </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  name="customerPrice"
                  label="Customer Price & Cost Target"
                  value={state.customerPrice}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <label>Service Duration</label>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Number Only"
                      type="number"
                      value={state.serviceDuration}
                      name="serviceDuration"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required variant="outlined">
                      <InputLabel id="typeOfPeriod">
                        Type Of Period
                      </InputLabel>
                      <Select
                        labelId="typeOfPeriod"
                        value={state.servicePeriod}
                        name="servicePeriod"
                        onChange={handleChange}
                      >
                        <MenuItem disabled>Select</MenuItem>
                        <MenuItem value="Days">Days</MenuItem>
                        <MenuItem value="Weeks">Weeks</MenuItem>
                        <MenuItem value="Months">Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <label>Expected Mobilization Date *</label>
                <DatePicker
                  className={classes.marginBottom}
                  autoOk
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  // label="Date Received"
                  fullWidth
                  required
                  inputVariant="outlined"
                  value={mobilizationDate}
                  onChange={handleChangeMobilizationDate}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                        <InputAdornment position="end">
                          <IconButton
                            disabled={mobilizationDate === null}
                            onClick={(e) => {
                              e.stopPropagation();
                              setMobilizationDate(null);
                            }}
                          >
                            {mobilizationDate ? (
                              <CloseIcon />
                            ) : (
                              <FilterListIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </Grid>
              {isServices || (cwsr && cwsr.status === "accepted" && isBD) ? (
                <>
                  <Grid item xs={12} md={4}>
                    <label>Service Method(s)</label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      name="serviceMethod"
                      label="Service Method(s)"
                      value={state.serviceMethod}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label>
                      Regulatory Requirements *
                    </label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Regulatory Requirements"
                      name="regulatoryRequirement"
                      value={state.regulatoryRequirement}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label htmlFor="otherRequirement">
                      Other Specific requirement necessary to meet customer's
                      expectations *
                    </label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Other Specific requirements"
                      name="otherRequirement"
                      value={state.otherRequirement}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography variant="overline" className={classes.title2}>
                  Kindly attach any supporting documents, if available
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={document && document.length === 0}
                  onClick={handleOpenAttachment}
                >
                  <Badge color="secondary" badgeContent={document && document.length}>
                    <AttachFileIcon />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <FormControl fullWidth variant="outlined">
              <label htmlFor="file2">Attachment</label>
              <input
                type="file"
                variant="outlined"
                accept="image/*,.pdf"
                multiple
                id="file2"
                className={classes.input}
                onChange={handleUploadDocument}
              />
            </FormControl>
          </Paper>

          {/* To be filled by the service division head or designate */}
          {isServices || (cwsr && cwsr.status === "accepted" && isBD) ? (
            <>
              <Typography variant="overline" className={classes.title2}>
                F. Manpower Requirements
              </Typography>

              <Paper variant="outlined" square className={classes.paper}>
                {state.manpowerRequirement && state.manpowerRequirement.map((item, i) => (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                    key={i}
                  >
                    <Grid item xs={12} md={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Qualification"
                        name="qualification"
                        value={item.qualification || ""}
                        onChange={handleChangeManpower(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Quantity"
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={handleChangeManpower(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth required variant="outlined">
                        <InputLabel id="nationalExpat">
                          National / Expat
                        </InputLabel>
                        <Select
                          labelId="nationalExpat"
                          value={item.source || ""}
                          name="source"
                          onChange={handleChangeManpower(i)}
                        >
                          <MenuItem disabled>Select</MenuItem>
                          <MenuItem value="National">National</MenuItem>
                          <MenuItem value="Expat">Expat</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Fab
                        color="secondary"
                        onClick={() => handleRemoveManpower(i)}
                      >
                        <CloseIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                ))}

                <Button
                  variant="outlined"
                  color="default"
                  onClick={() =>
                    handleAddManpower(state.manpowerRequirement.length + 1)
                  }
                  endIcon={<AddIcon fontSize="large" />}
                >
                  Insert Item
                </Button>
              </Paper>

              <Typography variant="overline" className={classes.title2}>
                G. Equipment/Material/Power Requirements
              </Typography>

              <Paper variant="outlined" square className={classes.paper}>
                {state.equipmentRequirement && state.equipmentRequirement.map((item, i) => (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                    key={i}
                  >
                    <Grid item xs={12} md={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Equipment"
                        name="equipment"
                        value={item.equipment || ""}
                        onChange={handleChangeEquipment(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Quantity"
                        type="number"
                        value={item.quantity}
                        name="quantity"
                        onChange={handleChangeEquipment(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth required variant="outlined">
                        <TextField
                          variant="outlined"
                          fullWidth
                          required
                          name="specification"
                          label="Specification"
                          value={item.specification}
                          onChange={handleChangeEquipment(i)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Fab
                        color="secondary"
                        onClick={() => handleRemoveEquipment(i)}
                      >
                        <CloseIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                ))}

                <Button
                  variant="outlined"
                  color="default"
                  onClick={() =>
                    handleAddEquipment(state.equipmentRequirement.length + 1)
                  }
                  endIcon={<AddIcon fontSize="large" />}
                >
                  Insert Item
                </Button>
              </Paper>

              <Typography variant="overline" className={classes.title2}>
                H. Required Consumables
              </Typography>

              <Paper variant="outlined" square className={classes.paper}>
                {state.consumableRequirement && state.consumableRequirement.map((item, i) => (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                    key={i}
                  >
                    <Grid item xs={12} md={3}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Consumable"
                        value={item.consumable}
                        name="consumable"
                        onChange={handleChangeConsumable(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Quantity"
                        type="number"
                        value={item.quantity}
                        name="quantity"
                        onChange={handleChangeConsumable(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth required variant="outlined">
                        <TextField
                          variant="outlined"
                          fullWidth
                          required
                          name="specification"
                          label="Specification"
                          value={item.specification}
                          onChange={handleChangeConsumable(i)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        name="requiredPPE"
                        value={item.requiredPPE}
                        label="Required PPE"
                        onChange={handleChangeConsumable(i)}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Fab
                        color="secondary"
                        onClick={() => handleRemoveConsumable(i)}
                      >
                        <CloseIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() =>
                    handleAddConsumables(
                      state.consumableRequirement.length + 1
                    )
                  }
                  endIcon={<AddIcon fontSize="large" />}
                >
                  Insert Item
                </Button>
              </Paper>
            </>
          ) : null}

          {cwsr && (cwsr.status !== "draft") ? (
            <>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
              >
                <Grid item>
                  <Typography variant="overline" className={classes.title2}>
                    Audit Trail
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
              </Grid>
              <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Paper
                      className={classes.auditContainer}
                      component="div"
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <span className={classes.auditTitle}>Submitted By</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.submittedBy}</span>
                      <Divider orientation="vertical" flexItem />
                      <span className={classes.audit}>{state.submittedAt}</span>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  {state.reviewedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Reviewed By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.reviewedBy}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.reviewedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Reviewer Comment</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.reviewerComment}</span>
                        </Paper>
                      </Grid>
                    </>
                  )}

                  {state.declinedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Declined By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.declinedBy}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.declinedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Comment</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.declineComment}</span>
                        </Paper>
                      </Grid>
                    </>
                  )}

                  {state.rejectedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Rejected By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.rejectedBy}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.rejectedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Comment</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.rejectComment}</span>
                        </Paper>
                      </Grid>
                    </>
                  )}

                  {state.approvedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Approved By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.approvedBy}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.approvedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Approver Comment</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.approverComment}</span>
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {state.acceptedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Accepted By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.acceptedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Accept Comment</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.accepterComment}</span>
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {state.modifiedBy && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Paper
                          className={classes.auditContainer}
                          component="div"
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <span className={classes.auditTitle}>Modified By</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.modifiedBy}</span>
                          <Divider orientation="vertical" flexItem />
                          <span className={classes.audit}>{state.modifiedAt}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}></Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </>
          ) : null}

          {cwsr && (cwsr.status === "draft" || cwsr.status === "declined" || cwsr.status === "rejected" || cwsr.status === "tomodify") ? (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <ButtonGroup>
                  <Button
                    type="submit"
                    className={classes.saveBtn}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Save"}
                  </Button>
                  <Button
                    className={classes.saveBtn2}
                    variant="contained"
                    onClick={handleSubmitHandler}
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          ) : cwsr && cwsr.status === "approved" && isCommercials ? (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <ButtonGroup>
                  <Button
                    type="submit"
                    className={classes.saveBtn}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Save"}
                  </Button>
                  <Button
                    className={classes.saveBtn2}
                    variant="contained"
                    onClick={handleOpenReview}
                  >
                    Review
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          ) : null}

          {(cwsr && cwsr.status === "accepted") && isBD ? (
            <>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <Typography variant="overline" className={classes.title2}>
                    Request Changes To CWSR
                  </Typography>
                </Grid>
              </Grid>
              <Paper variant="outlined" square className={classes.paper} style={{ padding: 15 }}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                      Kindly attach a supporting document to your request. Please attach all documents as a single document (.pdf, .doc are accepted formats)
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={state.modificationDoc === ""}
                      onClick={handleOpenReasonAttachment}
                    >
                      <Badge color="secondary">
                        <AttachFileIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="file3">Attach a document supporting your claim</label>
                  <input
                    type="file"
                    variant="outlined"
                    required={(cwsr && cwsr.status === "accepted") && isBD}
                    accept="image/*,.pdf"
                    multiple
                    id="file3"
                    className={classes.input}
                    onChange={handleUploadReasonDocument}
                  />
                </FormControl>

                <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <label>Reason for Change(s)</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    required={(cwsr && cwsr.status === "accepted") && isBD}
                    name="modificationReason"
                    label="Reason for Change(s)"
                    value={state.modificationReason}
                    onChange={handleChange}
                  />
                </Grid>
              </Paper>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <ButtonGroup>
                    <Button
                      className={classes.saveBtn}
                      color="primary"
                      variant="contained"
                      onClick={handleSubmitReasonHandler}
                      disabled={(state.modificationDoc === "") || (state.modificationReason === "")}
                    >
                      {loading ? "Loading..." : "Submit Changes"}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </>
          ) : null}
        </form>
      </MuiPickersUtilsProvider>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default connect(null, { uploadDoc, updateCwsr, submitCwsr })(EditCWSR);
